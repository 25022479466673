import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CourseDescriptionComponent } from './course-description/course-description.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogDescriptionComponent } from './blog-description/blog-description.component';
export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  // { path: 'course-description/:id', component: CourseDescriptionComponent },
  { path: 'course-description/:id', component: CourseDescriptionComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blog/:slug', component: BlogDescriptionComponent },

  // {
  //   path: "course-description",
  //   loadChildren: () => import("./course-description/course-description.component").then((m) => m.CourseDescriptionComponent),
  //   data: { title: "course-description" }
  // },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
