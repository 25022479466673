import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  studentsZone = [
    'Blog', 'Internship', 'Tutorials', 'Video Reviews', 'Online Training Reviews', 'Reviews', 'Interview Questions',
    'Placed Students List', 'Collect GST Invoice'
  ];

  company = [
    'About Us', 'Services', 'Branches', 'Contact Us', 'Careers', 'Corporate Training', 'Become an Instructor',
    'Franchise Opportunities', 'Hire Talent', 'Privacy Policy', 'Cookie Policy', 'Terms of Use',
    'Refund/Cancellation Policy'
  ];

  onlineCourses = [
    'AWS Online Training', 'DevOps Certification Training Course', 'Python Online Training', 'Selenium Online Training',
    'Data Science Online Training', 'Data Science with Python Online Training', 'Machine Learning Online Training',
    'Power BI Online Training', 'Azure Online Training', 'Full Stack Developer Online Training',
    'Artificial Intelligence Online Training'
  ];
}
