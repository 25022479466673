import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { CommonServiceService } from "./common-service.service";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private readonly api = `${environment.API_URL}/v1/`;

    constructor(private http: HttpClient,  private readonly common: CommonServiceService) { }

    get(url: string): Observable<any> {
        return this.http.get<any>(`${this.api}${url}`).pipe(catchError(this.handleError(url, [])));
    }

    post(url: string, data: any, disableSecurity?: boolean): Observable<any> {
        // const postData = {
        //     data: this.security.encrypt(btoa(this.common.getLocalData(environment.AUTH_NAME + 'starttime')), data),
        //     time: this.common.getLocalData(environment.AUTH_NAME + 'starttime')
        // };
        return this.http.post<any>(`${this.api}${url}`, disableSecurity ? data : data);
        // .pipe(catchError(this.handleError(url, [])));
    }

    put(url: string, data: any, disableSecurity?: boolean): Observable<any> {
        const postData = {
            // data: this.security.encrypt(btoa(this.common.getLocalData(environment.AUTH_NAME + 'starttime')), data),
            // time: this.common.getLocalData(environment.AUTH_NAME + 'starttime')
        };
        return this.http.put<any>(`${this.api}${url}`, disableSecurity ? data : postData)
            .pipe(catchError(this.handleError(url, [])));
    }
    delete(url: string, data: any, disableSecurity?: boolean): Observable<any> {
        const postData = {
            // data: this.security.encrypt(btoa(this.common.getLocalData(environment.AUTH_NAME + 'starttime')), data),
            // time: this.common.getLocalData(environment.AUTH_NAME + 'starttime')
        };
        return this.http.delete<any>(`${this.api}${url}`, disableSecurity ? data : postData)
            .pipe(catchError(this.handleError(url, [])));
    }

    handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            return of(error as T);
        };
    }
}





