import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon'; // Import MatIconModule
import { CommonModule } from '@angular/common';  // Import CommonModule
import { MatCardModule } from '@angular/material/card';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterModule } from '@angular/router';
import { ApiService } from '../../shared/services/api-service.service';
import { FormsModule } from '@angular/forms';  // Import FormsModule here
import { MatTabsModule } from '@angular/material/tabs';  // Import MatTabsModule
import { ChangeDetectorRef } from '@angular/core';
interface Course {
  title: string;
  rating: string;
  description: string;
  color: string;
  subItems?: any // Optional property
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatIconModule, MatTabsModule, FormsModule, CommonModule,RouterModule, MatCardModule, MatExpansionModule, HeaderComponent, FooterComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  search = '';
  activeSubMenu: string = '';
  searchedSubCourses : any[] = [];
  showDropdownList: boolean = false;
  coursessassssfsd: any[] = [];

  toggleSubMenu(menu: string) {
    this.activeSubMenu = this.activeSubMenu === menu ? '' : menu;
  }



  constructor(private cdr: ChangeDetectorRef,readonly common: CommonServiceService, private title: Title, private router: Router, private api : ApiService) {
    this.title.setTitle(`NPS Edge Technologies`);
    this.common.getBreadCrumData().subscribe((data: any) => {
      if (data) {
        this.title.setTitle(`NPS Edge Technologies - ${data}`);
      }
    });
  }
  @ViewChild('carouselReview') carouselReview: ElementRef | undefined;
  @ViewChild('carouselCourse') carouselCourse: ElementRef | undefined;

  scrollCarousel(direction: string, carouselType: string) {
    const scrollAmount = 300;
    const carousel = carouselType === 'review' ? this.carouselReview : this.carouselCourse;

    if (carousel) {
      carousel.nativeElement.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      const time = this.common.getLocalData('AUTH_NAME_starttime');
      if (typeof time === 'object') {
        this.common.setLocalData('AUTH_NAME_starttime', new Date().getTime());
      }
    }, 1000);
    this.getMainCourseList();
    this.getCourses();
    this.getTrendingCourses();
  }

  getMainCourseList() {
    // this.api.get('Course/GetMainCoursesWebSite').subscribe((resp: { status: any; data: any; }) => {
    //   if (resp.status) {
    //     this.courseTypesList = resp.data;
    //   }
    // });
  }




  // Handle course detail navigation
  ViewCourseDetails(courseID: number) {
    alert(`You selected course with ID: ${courseID}`);
  }

  // Navigate to home
  navtohome() {
    this.router.navigate(['/login']);
  }

  courses = [
    { title: 'Full Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'MEAN Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'MERN Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Full Stack Developer Java and Automation Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Frontend Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Backend Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Full Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Full Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Full Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },
    { title: 'Full Stack Developer Training in Bangalore', rating: '1865 Ratings (5.0)', description: 'Full Stack Developer Course...', color: '#f70776' },

    // More courses...
  ];

  instagramLink = 'https://instagram.com/your-link';
  youtubeReviewLink = 'https://youtube.com/your-youtube-review-link';

  // Accordion functionality
  accordionItems = [
    { title: 'Introduction about NPS Technologies', content: 'NPS Edge Technologies has quickly established itself as a leader in the IT Software Training and Placements sector, securing a prominent and essential position. Effective company management involves planning, control, coordination, and motivation, and NPS Edge Technologies in Bangalore exemplifies this approach. Our dedicated coordinators expertly manage subjects, lectures, and problem-solving to ensure comprehensive training and successful outcomes for our students.' },
    { title: 'Our Features', content: 'Flexible learning options, expert trainers...' },
    { title: 'Online Training and Certification', content: 'Explore our latest courses...' }
  ];
  accordionStates: boolean[] = [];

  toggleAccordion(index: number): void {
    this.accordionStates[index] = !this.accordionStates[index];
  }

  selectedType: string = '';

  selectOption(type: string): void {
    this.selectedType = type;
  }

  reviews = [
    { name: 'Karthi', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'Congrats Karthi, 3 Years career break and B.Sc Maths to IT with 3.5 LPA. Get hired within 2 months.', youtubeLink: 'https://youtube.com/link-to-karthi-review' },
    { name: 'Pratik', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'I completed my MBA, Salesforce course, and got placed with 5 LPA. Thanks to NPS Technologies.', youtubeLink: 'https://youtube.com/link-to-pratik-review' },
    { name: 'Priyadarshini', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'Congratulations Priyadarshini, 6 Years career gap to Software Tester with 3.83 LPA. Get hired within 2 months.', youtubeLink: 'https://youtube.com/link-to-priyadarshini-review' },
    { name: 'Karthi', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'Congrats Karthi, 3 Years career break and B.Sc Maths to IT with 3.5 LPA. Get hired within 2 months.', youtubeLink: 'https://youtube.com/link-to-karthi-review' },
    { name: 'Pratik', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'I completed my MBA, Salesforce course, and got placed with 5 LPA. Thanks to NPS Technologies.', youtubeLink: 'https://youtube.com/link-to-pratik-review' },
    { name: 'Priyadarshini', image: 'https://img.freepik.com/free-photo/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background_1258-65334.jpg?size=626&ext=jpg', text: 'Congratulations Priyadarshini, 6 Years career gap to Software Tester with 3.83 LPA. Get hired within 2 months.', youtubeLink: 'https://youtube.com/link-to-priyadarshini-review' },

  ];

  getSubCourses(){
    console.log(this.search)
    // this.searchedSubCourses = [
    //   {name : 'Full stack dev', value: 5},
    //   {name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},{name : 'Full stack dev', value: 5},
    // ]
    if(this.search.length > 0){
      this.api.get(`Course/subcourse/${this.search}`).subscribe((resp:any) => {
        console.log("search",resp);
        this.searchedSubCourses = resp;
      })
    }
    else{
      this.searchedSubCourses  = []
    }
  }

  navigateToDescription(subcourseId: string): void {
    this.router.navigateByUrl(`/course-description/${subcourseId}`);
  }

  coursessasss : any[] = [];
getCourses(){
  this.api.get('Course/GetWebSiteCourses').subscribe((response: any) => {
    console.log("response", response);
    if (response.status) {
      this.coursessasss = response.data;
    }else{
      this.searchedSubCourses = [];
      this.showDropdownList = false;
    }
  })
}
getTrendingCourses(){
  this.api.get('Admin/getTrend').subscribe((response: any) => {
    console.log("response", response);
    // if (response.status) {
      this.coursessassssfsd = response.data;
      this.cdr.detectChanges();
    // }else{
    //   this.searchedSubCourses = [];
    //   this.showDropdownList = false;
    // }
  })
}
  // coursessasss = [
  //   {
  //     name: 'Master Program',
  //     subcoursesdd: ['Big Data Masters Program', 'Business Intelligence', 'Cloud Master Program', 'Data Science']
  //   },
  //   {
  //     name: 'Cloud Computing',
  //     subcoursesdd: ['AWS', 'Azure', 'Google Cloud', 'IBM Cloud']
  //   },
  //   {
  //     name: 'Data Science & AI',
  //     subcoursesdd: ['Machine Learning', 'Deep Learning', 'AI Engineering', 'Data Analysis']
  //   },
  //   {
  //     name: 'DevOps',
  //     subcoursesdd: ['DevOps Master', 'Kubernetes', 'Docker', 'CI/CD']
  //   },
  //   {
  //     name: 'DevOps',
  //     subcoursesdd: ['DevOps Master', 'Kubernetes', 'Docker', 'CI/CD']
  //   },
  //   {
  //     name: 'DevOps',
  //     subcoursesdd: ['DevOps Master', 'Kubernetes', 'Docker', 'CI/CD']
  //   },
  //   {
  //     name: 'DevOps',
  //     subcoursesdd: ['DevOps Master', 'Kubernetes', 'Docker', 'CI/CD']
  //   },
  //   {
  //     name: 'DevOps',
  //     subcoursesdd: ['DevOps Master', 'Kubernetes', 'Docker', 'CI/CD']
  //   },
  //   // Add more courses as needed
  // ];

  selectedCourseIndex: number = 0;

  selectCourse(index: number) {
    this.selectedCourseIndex = index;
  }

  // Array of gradient colors for backgrounds
  gradientColors = [
    'linear-gradient(135deg, #4a90e2, #9013fe)',
    'linear-gradient(135deg, #ff7e5f, #feb47b)',
    'linear-gradient(135deg, #43cea2, #185a9d)',
    'linear-gradient(135deg, #ffafbd, #ffc3a0)',
    'linear-gradient(135deg, #2193b0, #6dd5ed)'
  ];

  // Method to get the background color based on the card index
  getBackgroundColor(index: number): string {
    return this.gradientColors[index % this.gradientColors.length];
  }


}
