import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    constructor() { }

    encrypt(key: string, data: any) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    }

    decrypt(key: string, data: any) {
        const decryptData = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
        return decryptData ? JSON.parse(decryptData) : {};
    }
}

