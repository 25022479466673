<!-- Banner Section -->
<section class="full-stack-banner">
  <div class="container">
    <div class="banner-content">
      <div class="course-info">
        <h1>{{courseData.Title}}</h1>
        <!-- <div class="ratings">
          <span class="best-seller">BEST SELLER</span>
          <span class="stars">★★★★★</span>
          <span class="rating-count">2245 Ratings (5.0)</span>
        </div> -->
        <p class="description">
          {{ courseData.Description }}
        </p>
        <!-- <button class="cta-button">Get Free Live Videos</button> -->

        <!-- Details Section -->
        <div class="details">
          <!-- <div class="detail-item">
            <img src="../../../assets/images/assignment.jpg" alt="Assignments">
            <span>Assignments</span>
            <p>20 Hours</p>
          </div> -->
          <!-- <div class="detail-item">
            <img src="../../../assets/images/learners.png" alt="Support">
            <span>Total Learners</span>
            <p>2245 Learners</p>
          </div> -->
          <div class="detail-item">
            <img src="../../../assets/images/duration.png" alt="Skill Level">
            <span>Duration</span>
            <p>{{courseData.Duration}}</p>
          </div>
        </div>

        <!-- Course Start and End Dates -->
        <div class="startEnd">
          <div class="start">Course Start Date: {{ courseData.StartDate | date: 'dd-MM-yyyy' }}</div>
          <div class="end">Course End Date: {{ courseData.EndDate | date: 'dd-MM-yyyy' }}</div>
        </div>
      </div>

      <!-- Course Card -->
      <div class="course-card">
        <div class="card">
          <div class="card-header">
            <h4>{{courseData.Title}}</h4>
          </div>
          <div class="card-body">
            <h6>Course Syllabus:</h6>
            <ul>
              <li *ngFor="let syllabus of courseSyllabus">{{ syllabus.Title }}</li>
            </ul>
            <br>
            <div class="amount-details">
              <p> Total Amount: ₹ {{ courseData.Cost + 10000 }}</p>
              <p>Discount Offered: ₹ 10000</p>
              <p><strong>Amount To Pay: ₹ {{courseData.Cost}}</strong></p>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn" (click)="openLoginModal(courseData.ID, courseData.Cost, courseData.Title)">Enroll Now</button>
            &nbsp;&nbsp;&nbsp;
            <!-- <button class="btn" (click)="downloadSyllabus()">Download Syllabus</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Batch Schedule Section -->
<section class="batch-schedule">
  <h4>Batch Schedule for Full Stack Masters Program</h4>
  <table class="schedule-table">
    <tr>
      <th>Date</th>
      <th>Batch</th>
      <th>Timings</th>
      <!-- <th>Action</th> -->
    </tr>
    <tr>
      <td> {{ courseData.StartDate | date: 'dd-MM-yyyy' }}</td>
      <td>Mon-Sat (Weekdays)</td>
      <td>09:00 AM (IST)</td>
      <!-- <td><button class="get-fees-button">Get Fees</button></td> -->
    </tr>
    <!-- Additional rows here... -->
  </table>
  <!-- <button class="request-batch-button">Request A Batch</button> -->
</section>
