import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './routes/app.routes';
// import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient,withFetch  } from '@angular/common/http';  // Import provideHttpClient
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }),  provideRouter(routes,       withInMemoryScrolling({
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  }),),
     //provideClientHydration(),
      provideAnimationsAsync(),  provideHttpClient(withFetch()),  // Enable fetch API here
    provideAnimationsAsync(), provideAnimationsAsync(),{ provide: LocationStrategy, useClass: PathLocationStrategy }]
};
