<h5 mat-dialog-title style="text-align: center; color: #4A90E2;">{{ data.courseName }}</h5>
<div style="text-align: center; margin-bottom: 0px;">
  <h6 style="color: #F39C12;">Price: ${{ data.coursePrice }}</h6>
</div>
<form [formGroup]="courseForm" (ngSubmit)="onSubmit()" class="course-form">
  <mat-dialog-content>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Full Name</mat-label>
      <input matInput required formControlName="fullName" />
      <mat-error *ngIf="courseForm.get('fullName')?.invalid && courseForm.get('fullName')?.touched">
        Full Name is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Mobile Number</mat-label>
      <input matInput required formControlName="mobileNumber" />
      <mat-error *ngIf="courseForm.get('mobileNumber')?.invalid && courseForm.get('mobileNumber')?.touched">
        Valid Mobile Number is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Email</mat-label>
      <input matInput required formControlName="email" />
      <mat-error *ngIf="courseForm.get('email')?.invalid && courseForm.get('email')?.touched">
        Valid Email is required.
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button (click)="onClose()">Cancel</button>
    <button mat-button type="submit" color="primary">Buy Now</button>
  </mat-dialog-actions>
</form>
