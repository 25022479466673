<!-- footer.component.html -->
<footer class="footer">
  <div class="footer-container">
    <!-- Left side of footer -->
    <div class="footer-left">
      <!-- Students Zone -->
      <!-- <div class="footer-section">
        <h4>Students Zone</h4>
        <ul class="horizontal-list">
          <li *ngFor="let item of studentsZone"><a href="#">{{ item }}</a></li>
        </ul>
      </div> -->

      <!-- Company -->
      <!-- <div class="footer-section">
        <h4>Company</h4>
        <ul class="horizontal-list">
          <li *ngFor="let item of company"><a href="#">{{ item }}</a></li>
        </ul>
      </div> -->

      <!-- Top Online Courses -->
      <!-- <div class="footer-section">
        <h4>Top Online Courses</h4>
        <ul class="horizontal-list">
          <li *ngFor="let item of onlineCourses"><a href="#">{{ item }}</a></li>
        </ul>
      </div> -->

      <!-- Chennai Branches List -->
      <div class="footer-note">
        <p>We are conveniently located in several areas around Bangalore. If you are staying or looking for
          training in any of these areas, please get in touch with our career counselors.</p>
        <!-- <p><strong>Areas in Chennai:</strong> Adambakkam, Adyar, Alandur, Arumbakkam, Ashok Nagar, NPS Nagar,
          Chitlapakkam, Guindy, T. Nagar, and many more...</p> -->
        <p>PS: We assure that traveling 10 - 15 minutes additionally will lead you to the best training institute that
          is worth your time and money.</p>
      </div>
    </div>

    <!-- Right side of footer -->
    <!-- <div class="footer-right">
      <h4>Follow Us!</h4>
      <div class="social-icons">
        <a href="#"><i class="fab fa-facebook-f"></i></a>
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-linkedin-in"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
      </div>
    </div> -->
  </div>

  <!-- Footer Disclaimer -->
  <div class="footer-disclaimer">
    <p>&copy; NPS Edge Technologies Pvt Ltd. All Rights Reserved. The certification names are the
      trademarks of their respective owners. <a href="#">View Disclaimer</a></p>
  </div>
</footer>


