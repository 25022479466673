<div class="blog-detail-container" *ngIf="BlogsData">
  <div class="blog-header" [style.backgroundImage]="'url(' + BlogsData.IconImage + ')'">
    <h1 style="color: black; font-size: x-large;">{{ BlogsData.Title }}</h1>
    <div class="blog-meta">
      <span style="color: black; font-size: large;"><i class="fa fa-calendar" ></i> {{ BlogsData.StartDate | date: 'dd/MM/yyyy' }}</span>
    </div>
  </div>
  <div class="blog-content">
    <div [innerHTML]="sanitizedBriefDescription"></div>
  </div>
</div>
