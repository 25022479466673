

<div class="top-bar">
  <div class="orange-bar">
    <p>We Offer 100% Job Guarantee Courses (Any Degree / Diploma Candidates / Year GAP / Non-IT / Any Passed Outs).</p>
  </div>
  <div class="blue-bar">
    <ul class="contact-info">
      <li>Bangalore: <a href="tel:+918767260270">+91-XXXXXXXXXX</a></li>
      <li>Mysore: <a href="tel:+918767260270">+91-XXXXXXXXXX</a></li>
    </ul>
  </div>
</div>

<div class="navbar">
  <div class="logo">
    <img src="../../../assets/images/npslogo.jpeg" alt="Logo" />
  </div>

  <button class="navbar-toggler" type="button" (click)="toggleMobileMenu()">
    <span class="navbar-toggler-icon">☰</span>
  </button>

  <nav class="nav-menu" [ngClass]="{'show': isMobileMenuOpen}">
    <ul class="nav-links">
      <li class="nav-item"><a class="nav-link" [routerLink]="['/home']">Home</a></li>

      <li class="nav-item dropdown" (click)="toggleDropdown($event)">
        <a class="nav-link">All Courses</a>
        <ul class="dropdown-menu" [ngClass]="{'show': isDropdownOpen}">
          <li class="dropdown-submenu" *ngFor="let course of coursesnavbar">
            <a class="dropdown-item" (click)="toggleSubMenu($event)">{{ course.name }}</a>
            <ul class="dropdown-submenu-menu">
              <li *ngFor="let subcourse of course.subCourse">
                <a class="dropdown-item" (click)="navigateToDescription(subcourse.Value)">{{ subcourse.name }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/blogs']">Blogs</a></li>

      <!-- <li class="nav-item"><a class="nav-link" >Branches</a></li> -->
      <li class="nav-item"><a class="nav-link" >Online Training</a></li>
      <li class="nav-item"><a class="nav-link" >Corporate Training</a></li>
    </ul>
  </nav>
</div>







