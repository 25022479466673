import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api-service.service';
import { BlogDataService } from '../../shared/services/blog-data.service';

@Component({
  selector: 'app-blogs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blogs.component.html',
  styleUrl: './blogs.component.css'
})
export class BlogsComponent {
  blogs :any[] = []
constructor(private router: Router, private api : ApiService, private blogDataService: BlogDataService){}
ngOnInit(){
  this.getBlogs();

}
  // blogs = [
  //   {
  //     id:5,
  //     title: 'Software Testing Syllabus',
  //     link: 'https://www.besanttechnologies.com/software-testing-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 14, 2022',
  //     comments: 0,
  //     description: 'The Software Testing syllabus from Besant Technologies covers all of the topics...',
  //     gradient: 'linear-gradient(45deg, #ff9800, #2196f3)' // Example gradient
  //   },
  //   {
  //     id:5,
  //     title: 'Salesforce Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/salesforce-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 11, 2022',
  //     comments: 0,
  //     description: 'Salesforce Course Syllabus created by Besant Technologies experts provides...',
  //     gradient: 'linear-gradient(45deg, #f44336, #2196f3)'
  //   },
  //   {
  //     id:5,
  //     title: 'Data Analytics Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/data-analytics-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 9, 2022',
  //     comments: 0,
  //     description: 'Our industry experts frame the Data Analyst Course Syllabus...',
  //     gradient: 'linear-gradient(45deg, #4caf50, #2196f3)'
  //   },
  //   {
  //     id:5,
  //     title: 'Software Testing Syllabus',
  //     link: 'https://www.besanttechnologies.com/software-testing-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 14, 2022',
  //     comments: 0,
  //     description: 'The Software Testing syllabus from Besant Technologies covers all of the topics...',
  //     gradient: 'linear-gradient(45deg, #ff9800, #2196f3)' // Example gradient
  //   },
  //   {
  //     id:5,
  //     title: 'Salesforce Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/salesforce-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 11, 2022',
  //     comments: 0,
  //     description: 'Salesforce Course Syllabus created by Besant Technologies experts provides...',
  //     gradient: 'linear-gradient(45deg, #f44336, #2196f3)'
  //   },
  //   {
  //     id:5,
  //     title: 'Data Analytics Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/data-analytics-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 9, 2022',
  //     comments: 0,
  //     description: 'Our industry experts frame the Data Analyst Course Syllabus...',
  //     gradient: 'linear-gradient(45deg, #4caf50, #2196f3)'
  //   },
  //   {
  //     id:5,
  //     title: 'Software Testing Syllabus',
  //     link: 'https://www.besanttechnologies.com/software-testing-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 14, 2022',
  //     comments: 0,
  //     description: 'The Software Testing syllabus from Besant Technologies covers all of the topics...',
  //     gradient: 'linear-gradient(45deg, #ff9800, #2196f3)' // Example gradient
  //   },
  //   {
  //     id:5,
  //     title: 'Salesforce Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/salesforce-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 11, 2022',
  //     comments: 0,
  //     description: 'Salesforce Course Syllabus created by Besant Technologies experts provides...',
  //     gradient: 'linear-gradient(45deg, #f44336, #2196f3)'
  //   },V
  //   {
  //     id:5,
  //     title: 'Data Analytics Course Syllabus',
  //     link: 'https://www.besanttechnologies.com/data-analytics-course-syllabus',
  //     image: './assets/images/background.webp',
  //     date: 'May 9, 2022',
  //     comments: 0,
  //     description: 'Our industry experts frame the Data Analyst Course Syllabus...',
  //     gradient: 'linear-gradient(45deg, #4caf50, #2196f3)'
  //   }
  // ];

  getBlogs(){
    this.api.get('Course/GetBlogsList').subscribe((resp : any) => {
      console.log(resp);
      if(resp.status){
        this.blogs = resp.blogs;
      }else{

      }
    })
  }

  navigateToBlog(slug: string, id: number) {
    const slug_text = this.generateSlug(slug);
  console.log(slug_text);

  // Store the id in the service
  this.blogDataService.setBlogId(id);

  this.router.navigate([`/blog/${slug_text}`]);

    // const slug_text = this.generateSlug(slug);
    // console.log(slug_text);
    // this.router.navigate([`/blog/${slug_text}`], { state: { id: id } });
  }

  generateSlug(title: string): string {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }
}
