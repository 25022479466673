<h5 mat-dialog-title style="text-align: center; margin: 0px 0px; padding: 5px;">{{' NPS Edge Login/Register'}}</h5>

<!-- Angular Material Tabs -->
<mat-tab-group>
  <!-- Login Tab -->
  <mat-tab label="Login">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
      <mat-dialog-content>
        <div class="form-container">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Email or Mobile</mat-label>
            <input matInput required formControlName="MobileNumber" />
            <mat-error *ngIf="loginForm.get('MobileNumber')?.invalid && loginForm.get('MobileNumber')?.touched">
              Email or Mobile is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Password</mat-label>
            <input matInput required type="Password" formControlName="Password" />
            <mat-error *ngIf="loginForm.get('Password')?.invalid && loginForm.get('Password')?.touched">
              Password is required.
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button mat-button (click)="onClose()">Cancel</button>
        <button mat-button type="submit" color="primary">Login</button>
      </mat-dialog-actions>
    </form>

  </mat-tab>

  <!-- Register Tab -->
  <mat-tab label="Register">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()">
      <mat-dialog-content>
        <div class="form-container">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="Name" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Mobile Number</mat-label>
            <input matInput required formControlName="MobileNumber" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Email</mat-label>
            <input matInput required formControlName="Email" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Password</mat-label>
            <input matInput required type="Password" formControlName="Password" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Repeat Password</mat-label>
            <input matInput required type="Password" formControlName="repeatPassword" />
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button mat-button (click)="onClose()">Cancel</button>
        <button mat-button type="submit" color="primary">Register</button>
      </mat-dialog-actions>
    </form>

  </mat-tab>
</mat-tab-group>

