import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  private profileData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private breadCrumData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private snackBar: MatSnackBar) {}

  // Check if sessionStorage is available (i.e., in the browser)
  private isSessionStorageAvailable(): boolean {
    return typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined';
  }

  isAuthenticated(): boolean {
    return this.isSessionStorageAvailable() &&
           Object.keys(this.getLocalData(environment.AUTH_NAME + "token")).length > 0;
  }

  setLocalData(key: string, data: any): void {
    if (this.isSessionStorageAvailable()) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  getLocalData(key: string): any {
    if (this.isSessionStorageAvailable() && sessionStorage.getItem(key)) {
      const data: any = sessionStorage.getItem(key);
      return JSON.parse(data);
    }
    return {};
  }

  removeLocalData(key?: string): void {
    if (this.isSessionStorageAvailable()) {
      if (key) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.clear();
      }
    }
  }

  notification(message: string, isError?: boolean): void {
    const panelClass = isError ? ['error-snackbar'] : ['success-snackbar'];
    this.snackBar.open(message, '', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: panelClass,
      duration: 3000
    });
  }

  setBreadCrumData(data: any): void {
    this.breadCrumData.next(data);
  }

  getBreadCrumData() {
    return this.breadCrumData.asObservable();
  }
}
