<!-- <app-header></app-header> -->

<!-- Hero Section -->
<section class="hero">
  <div class="container">
    <h1>NPS Edge Technologies </h1>
    <h5>Best Online & Classroom Training Institute Placement Services</h5>
    <div class="search-dropdown">
      <form class="search-form">
        <input type="text" name="search" [(ngModel)]="search" placeholder="Search course...">
        <button type="submit" (click)="getSubCourses()">Search</button>
      </form>
      <!-- Dropdown list for searched courses -->
      <ul class="dropdown">
        <li *ngFor="let course of searchedSubCourses" (click)="navigateToDescription(course.ID)">
          {{ course.Title }}
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="about-form-section">
  <div class="container">
    <h1 class="title">NPS Edge Technologies, Software Training & Placements in India</h1>
    <div class="columns">
      <!-- Accordion Content on the left side -->
      <div class="accordion-container">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let item of accordionItems">
            <mat-expansion-panel-header>
              <mat-panel-title style="margin-left:10px">
                {{ item.title }}
              </mat-panel-title>
              <!-- <mat-panel-description>
                <p>{{ item.content }}</p>
              </mat-panel-description> -->
            </mat-expansion-panel-header>
            <p style="text-align: justify; text-indent: 10%; margin-left:10px">{{ item.content }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- Form container on the right side -->
      <div class="form-container">
        <h5>Request for Class Room &amp; Online Training Quotation</h5>
        <div class="button-group">
          <button class="btn" [ngClass]="{ 'active': selectedType === 'Class Room' }"
            (click)="selectOption('Class Room')">
            Class Room
          </button>
          <button class="btn" [ngClass]="{ 'active': selectedType === 'Online' }" (click)="selectOption('Online')">
            Online
          </button>
        </div>

        <!-- Hidden input to store selected value -->
        <input type="hidden" name="trainingType" [value]="selectedType">
        <form>
          <input type="text" name="name" placeholder="Enter Your Name" required>
          <input type="email" name="email" placeholder="Enter Your Email" required>
          <input type="tel" name="phone" placeholder="Enter Your Mobile No." required>
          <select name="course" required>
            <option value="" disabled selected>Select Course</option>
            <option value="AI">Artificial Intelligence</option>
            <option value="Data Science">Data Science</option>
          </select>
          <button type="submit" class="submit-btn">Get Quotes</button>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Impact Section -->
<section class="impact">
  <div class="container">
    <h2 class="impact-title">Our Impact and Reach</h2>
    <div class="impact-stats">
      <mat-card class="stat-card">
        <h3>1 Million+</h3>
        <div class="underline"></div>
        <p>Students Enrolled</p>
      </mat-card>

      <mat-card class="stat-card">
        <h3>20+</h3>
        <div class="underline"></div>
        <p>Overall Branches</p>
      </mat-card>

      <mat-card class="stat-card">
        <h3>10000+</h3>
        <div class="underline"></div>
        <p>Placed Students</p>
      </mat-card>

      <mat-card class="stat-card">
        <h3>12+</h3>
        <div class="underline"></div>
        <p>Years of Experience</p>
      </mat-card>
    </div>
  </div>
</section>

<section class="why-choose-us">
  <div class="container">
    <h4>Why to <span style="color: blue;">Choose Us? </span></h4>
    <p class="whytoChooseP">A choice that makes a big difference in your career. NPS is committed to helping students
      reach their goals and training experiences by providing innovative surroundings and faculties.</p>
    <div class="features">
      <!-- First row of features -->
      <div class="feature">
        <div class="choose_icons"><i class="fa fa-user fa-3x" aria-hidden="true"></i></div>
        <h5>IT Experts as Trainers</h5>
        <p>Learning a technology with a professional who is well-expertise in that solves 60% of your needs.</p>
      </div>
      <div class="feature">
        <div class="choose_icons"><i class="fa fa-laptop fa-3x" aria-hidden="true"></i></div>
        <h5>Fully Hands-on Training</h5>
        <p>We support any training with more practical classes. We always prefer to give hands-on training.</p>
      </div>
      <div class="feature">
        <div class="choose_icons"><i class="fa fa-clock fa-3x" aria-hidden="true"></i></div>
        <h5>Flexible Timings</h5>
        <p>We started with 2 trainers, now we are 100+ and still increasing, providing flexibility in timings.</p>
      </div>
    </div>

    <div class="features">
      <!-- Second row of features -->
      <div class="feature">
        <div class="choose_icons"><i class="fas fa-money-bill-alt fa-3x" aria-hidden="true"></i></div>
        <h5>Affordable Fees</h5>
        <p>We offer quality training at the best price. NPS ensures training is affordable for everyone.</p>
      </div>
      <div class="feature">
        <div class="choose_icons"><i class="fa fa-desktop fa-3x" aria-hidden="true"></i></div>
        <h5>Lab Support</h5>
        <p>If you need software assistance, we are here to back you up. Bring your laptop and start learning.</p>
      </div>
      <div class="feature">
        <div class="choose_icons"><i class="fa fa-book fa-3x" aria-hidden="true"></i></div>
        <h5>Interview Preparation</h5>
        <p>Every course covers interview questions and real-time scenarios to help prepare you for job interviews.</p>
      </div>
    </div>
  </div>
</section>

<section class="belowchooseBlock">
  <div class="viewAllCourse">
    <p>Learning Today For A Better Tomorrow</p>
    <button class="view-all-button">View All Courses</button>
  </div>
</section>

<section class="students-review">
  <div class="container">
    <h3>Students <span class="highlight">Review</span></h3>
    <p style="text-align: center;"> Here we are presenting exclusive reviews given by our students who got benefits from
      our training.</p>

    <div class="carousel-container-course">
      <button class="scroll-btn left" (click)="scrollCarousel('left', 'review')">&#10094;</button>
      <div class="carousel-course" #carouselReview>
        <div class="carousel-item-course" *ngFor="let review of reviews">
          <div>
            <img [src]="review.image" alt="review.name" width="250px" height="150px">
          </div>
          <div class="carousel-item">
            <p>{{ review.text }}</p>
          </div>
        </div>
      </div>
      <button class="scroll-btn right" (click)="scrollCarousel('right', 'review')">&#10095;</button>
    </div>

    <!-- Social Buttons -->
    <div class="social-buttons">
      <a [href]="instagramLink" target="_blank" class="instagram-btn">Instagram Post</a>
      <a [href]="youtubeReviewLink" target="_blank" class="youtube-btn">YouTube Reviews</a>
    </div>
  </div>
</section>

<section class="course-section">
  <div class="container">
    <h3>Trending <span class="highlight">Courses</span></h3>
    <p style="text-align: center;">Explore popular courses</p>

    <div class="carousel-container-course">
      <button class="scroll-btn left" (click)="scrollCarousel('left', 'course')">&#10094;</button>
      <div class="carousel-course" #carouselCourse>
        <div class="carousel-item-course" *ngFor="let course of coursessassssfsd">
          <div class="course-item" [ngStyle]="{'background-color': course.color}">
            <p>{{ course.title }}</p>
          </div>
          <div class="couse_name_description">
            <p><span style="color: gold;">★★★★★</span></p>
            <div>
              <p>{{ course.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <button class="scroll-btn right" (click)="scrollCarousel('right', 'course')">&#10095;</button>
    </div>
  </div>
</section>

<div class="course-selection-container">
  <mat-tab-group [(selectedIndex)]="selectedCourseIndex" (selectedIndexChange)="selectCourse($event)">
    <mat-tab *ngFor="let course of coursessasss; let i = index" label="{{ course.name }}"></mat-tab>
  </mat-tab-group>

  <div class="subcourse-container">
    <mat-card *ngFor="let subcourse of coursessasss[selectedCourseIndex].subCourse; let i = index"
      class="subcourse-card hover-card">
      <mat-card-content>
        <div class="courseImage" [ngStyle]="{'background': getBackgroundColor(i)}">
          <h3>{{ subcourse.name}}</h3>
        </div>
        <br>
        <!-- <p>{{ subcourse }} description goes here.</p> -->
        <span>⭐⭐⭐⭐⭐ 1965 Ratings (5.0)</span>
        <p>{{ subcourse.name }} program</p>
        <!-- <p>👤 2245 Learners | 🎓 Beginner</p> -->
        <br>
        <!-- Pricing and buttons for hover effect (example) -->
        <div class="hover-details">
          <p><strong>Training Modes:</strong> Online | Classroom</p>
          <p>₹ {{subcourse.cost}} <s>₹ {{subcourse.cost +10000 }}</s></p>
          <div class="button-group">
            <button class="small-button" mat-button style="background-color: blueviolet; color: aliceblue;" (click)="navigateToDescription(subcourse.Value)">
              Enroll Now
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="small-button" mat-button style="background-color: orangered; color: aliceblue;" (click)="navigateToDescription(subcourse.Value)" >
              Read More
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<!-- <app-footer></app-footer> -->
