import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';  // Import ReactiveFormsModule
import { ApiService } from '../../shared/services/api-service.service';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { environment } from '../../../environments/environment';
import { SecurityService } from '../../shared/services/security.service';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { WindowRefService } from '../../shared/services/window-ref/window-ref.service';

@Component({
  selector: 'app-buy-course',
  standalone: true,
  imports:[CommonModule, FormsModule,MatDialogModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTabsModule],
  templateUrl: './buy-course.component.html',
  styleUrl: './buy-course.component.css'
})
export class BuyCourseComponent {
  courseName: string = 'Example Course'; // Set this dynamically if needed
  coursePrice: number = 199.99; // Example price
  courseForm: FormGroup;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<BuyCourseComponent>, @Inject(MAT_DIALOG_DATA) public data: { courseName: string; coursePrice: number, ID: number }, private api : ApiService, private security : SecurityService, private common : CommonServiceService, private winRef :WindowRefService) {
    this.courseForm = this.fb.group({
      fullName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.courseForm.valid) {
      // Handle the form submission
      console.log(this.courseForm.value, this.data);
      this.dialogRef.close();
      const paymentData ={...this.courseForm.value, ...this.data}
      this.confirmRazorpayPayment(paymentData)    }
  }

  onClose() {
    this.dialogRef.close();
  }

  confirmRazorpayPayment(paymentData: any) {
    Swal.fire({
      html: `Are you sure you want to pay via Razorpay?<br><h5>Amount: &#8377;${paymentData.coursePrice}</h5>`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      // customClass: 'subscription-swal'
    }).then(result => {
      if (result.isConfirmed) {
        this.createOrder(paymentData);
      }
    });
  }

  createOrder(paymentData: any) {
    // this.isLoading = true;
    // this.loader.showLoader()
    this.api.post('Razorpay/CreateOrder/BuyCourse', paymentData).subscribe(
      resp => {
        if (resp.Status) {
            this.payViaRazorPay(resp.data);
        } else {
          // this.isLoading = false;
          // this.loader.hideLoader()
          this.common.notification(resp.Message, true);
        }
      },
      err => {
        // this.isLoading = false;
        // this.loader.hideLoader()
        this.common.notification('Order creation failed. Please try again.', true);
      }
    );
  }

  payViaRazorPay(resp: any) {
   // const data = this.security.decrypt(btoa(resp.time), resp.data);
   const data = resp;
    console.log(data)
    const options: any = {
      key: data.KeyID,
      amount: data.coursePrice,
      currency: 'INR', //data.currency,
      name: 'NPS Edge tech',
      description: 'Subscription Payment',
      image: 'https://admin.getthrivetoken.com/assets/images/logo2.png',
      order_id: data.OrderID, //data.id,
      handler: (response: any) => {
        // this.isLoading = false;
        // this.loader.hideLoader()
        this.common.notification('Payment successful!', false);
        // this.api.post('users/add-spins-user', data.notes, true).subscribe((resp) => {
        //   console.log(resp);
        // })

        //  this.router.navigate(['/dashboard']);
      },
      modal: {
        ondismiss: () => {
          // this.isLoading = false;
          // this.loader.hideLoader()
          this.common.notification('Payment cancelled.', true);
        }
      },
      prefill: {
        email: data.email || '',
        contact: data.mobile || ''
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }


}
