import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';  // Import ReactiveFormsModule
import { ApiService } from '../../shared/services/api-service.service';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { environment } from '../../../environments/environment';
import { SecurityService } from '../../shared/services/security.service';
@Component({
  selector: 'app-login',
  standalone: true,
  imports:[CommonModule, FormsModule,MatDialogModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTabsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  loginForm!: FormGroup;
  registerForm!: FormGroup;
  constructor(public dialogRef: MatDialogRef<LoginComponent>, private fb: FormBuilder, private api : ApiService, private common : CommonServiceService, private security : SecurityService) {}
  loginData = {
    MobileNumber: ''
  };

  ngOnInit(): void {
    // Initialize login form
    this.loginForm = this.fb.group({
      MobileNumber: ['', Validators.required],
      Password: ['', Validators.required],
    });

    // Initialize register form
    this.registerForm = this.fb.group({
      Name: ['', Validators.required],
      MobileNumber: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', Validators.required],
      repeatPassword: ['', Validators.required],
    });
  }
  onSubmit() {
    // Handle login logic here, e.g., call an authentication service

    console.log(this.loginData);
    this.dialogRef.close(); // Close the modal after submission
  }

  onClose() {
    this.dialogRef.close(); // Close the modal
  }

  onSubmitLogin(): void {
    if (this.loginForm.valid) {
      const loginData = this.loginForm.value;
      console.log('Login Data:', loginData);
      // Implement your login logic here
      this.api.post('Account/Login', loginData).subscribe((resp : any) => {
        console.log(resp);
        if(resp.status){
          this.common.setLocalData(environment.AUTH_NAME + "token", this.security.encrypt(btoa(this.common.getLocalData(environment.AUTH_NAME + 'starttime')), resp.token));
          this.common.notification(resp.message);
        }else{
          this.common.notification(resp.message, true);
        }
      });
    }
  }

  // Handle register form submission
  onSubmitRegister(): void {
    if (this.registerForm.valid) {
      const registerData = this.registerForm.value;
      registerData.RoleID = 'User'
      this.api.get(`Account/CheckMobileExist/${registerData.MobileNumber}`).subscribe((resp : any) => {
        console.log(resp);
        if(resp.status){
          this.api.post('Account/Register', registerData).subscribe((response:any) => {
            console.log(response);
            if(response.status){

            }
          })
        }else{
          return resp.status;
        }
      })
      console.log('Register Data:', registerData);
      // Implement your register logic here
    }
  }

}
