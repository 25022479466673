import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogDataService {

constructor() { }
private blogId: number | null = null;

  setBlogId(id: number) {
    this.blogId = id;
  }

  getBlogId(): number | null {
    return this.blogId;
  }
}
