<div class="blog-container">
  <div *ngFor="let blog of blogs" class="blog-card" [ngStyle]="{'background': blog.gradient}">
    <a  (click)="navigateToBlog(blog.title, blog.id)" title="{{ blog.title }}">
      <div
        class="blog-thumb img-fluid"
        [ngStyle]="{'background-image': 'url(' + blog.image + ')'}">
        <h4 style="text-align: center; color: rgb(5, 5, 5); text-decoration: none; font-size: x-large; font-weight: 500;">{{ blog.title }}</h4>
      </div>
      <div class="blog-details">
        <br>
        <div class="entry-meta">
          <span><i class="fa fa-calendar" aria-hidden="true"></i> {{ blog.date | date : 'dd/MM/yyyy' }}</span>
          <span><i class="fa fa-comment" aria-hidden="true"></i> {{ blog.comments }}</span>
        </div>
        <br>
        <br>
        <p style="font-size: smaller; color: black; text-decoration: none;">{{ blog.description }}</p>
        <span class="read-more" style="font-size: smaller ; color: black; text-decoration: none;">Read More <i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
      </div>
    </a>
  </div>
</div>

