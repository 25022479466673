import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../shared/services/api-service.service';
import { BlogDataService } from '../../shared/services/blog-data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-description',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blog-description.component.html',
  styleUrl: './blog-description.component.css'
})
export class BlogDescriptionComponent {
  id: any;
  slug: any;
  BlogsData : any;
  sanitizedBriefDescription!: SafeHtml;

  constructor(private route: ActivatedRoute, private router: Router, private api :ApiService, private blogDataService: BlogDataService, private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    // Get the slug from the URL
    this.slug = this.route.snapshot.paramMap.get('slug');
    // Get the id from the route state
    const navigation = this.router.getCurrentNavigation();
    // const state = navigation?.extras.state as { id: number };
    this.id = this.blogDataService.getBlogId();

    // Use this.id to fetch the blog details if necessary

    console.log('ID:', this.id);
    console.log('Slug:', this.slug);
    this.getBlog();
  }

  getBlog(){
this.api.get(`Course/GetBlogData/${this.id}`).subscribe((resp : any) => {
  console.log(resp);
  if(resp.status){
    this.BlogsData = resp.BlogsData;
    // Replace `\n` with `<br>` and bind it in the HTML
    // / Replace `\n` with `<br>` and sanitize
    const modifiedDescription = this.BlogsData.BriefDescription.replace(/\\n/g, '');
    this.sanitizedBriefDescription = this.sanitizer.bypassSecurityTrustHtml(modifiedDescription);
    console.log("this.BlogsData", this.BlogsData)
  }
})
  }
  // BlogsData =
  //   {
  //     "ID": 2,
  //     "CourseID": 5,
  //     Title: "Test Blog ",
  //     Description: "<p>live and recorded classes</p>\n<p>3-4 months batch&nbsp;</p>\n<p>one year record classes</p>\n<p>doubt clearing facility</p>\n<p>googlemeet and personal guidence</p>\n<p>&nbsp;</p>\n<p>25 TEST PAPERS</p>\n<ul style=\"list-style-type: square;\">\n<li style=\"text-align: left;\">12 SUBJECTWISE TEST&nbsp;</li>\n<li style=\"text-align: left;\">10 FULL LENGTH TEST</li>\n<li style=\"text-align: left;\">03 BOOSTER TEST</li>\n<li style=\"text-align: left;\">ANSWERS AND EXPLANATION WITH PDF</li>\n<li style=\"text-align: left;\">NEGATIVE MARKS CALCULTION</li>\n<li style=\"text-align: left;\">PERFORMANCE ANALYSIS</li>\n<li style=\"text-align: left;\">GRAPH ANALYSIS</li>\n</ul>\n<p>ವಿಶೇಷತೆಗಳು</p>\n<p>ಸಾಮಾನ್ಯ ಕನ್ನಡ</p>\n<p>ಸಾಮಾನ್ಯ ಇಂಗ್ಲಿಷ್</p>\n<p>ಕಂಪ್ಯೂಟರ್ ಜ್ಞಾನ</p>\n<p>45 ದಿನಗಳ ಕ್ರಾಸ್ ಕೋರ್ಸ್</p>\n<p>ಪಿಡಿಎಫ್ ನೋಟ್ಸ್</p>\n<p>RECORDED AND LIVE CLASSES</p>",
  //     StartDate: "2023-09-28T12:43:47.983Z",
  //     "IconImage": "assets/images/blog-b.jpg",
  //     "CreatedDate": "2023-09-28T12:43:47.983Z",
  //     "CreatedBy": null,
  //     "ModifiedDate": null,
  //     "ModifiedBy": null,
  //     "Status": true
  //   }

}
