import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../shared/services/api-service.service';
import { response } from 'express';
import { HttpClient } from '@angular/common/http'; // Import HttpClient
import { LoginComponent } from '../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon'; // Import MatIconModule
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule, FontAwesomeModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  constructor(private api: ApiService, private http: HttpClient, public dialog: MatDialog, private router: Router) { }
  coursesnavbar: any[] = [];
  faBars = faBars;

  ngOnInit(): void {
    this.getDataFromAPI();
  }

  navigateToDescription(subcourseId: string): void {
    this.router.navigateByUrl(`/course-description/${subcourseId}`);
  }

  getDataFromAPI(): void {
    // this.api.get('ourse/GetMainCoursesWebSite').subscribe(
    //   (response) => {
    //     console.log('API Response:', response); // Log the API response to console
    //   },
    //   (error) => {
    //     console.error('Error fetching data from API:', error); // Handle error
    //   }
    // );

    this.api.get('Course/GetWebSiteCourses').subscribe((response: any) => {
      console.log("response", response);
      if (response.status) {
        this.coursesnavbar = response.data;
      }
    })
  }
  // coursesnavbar = [
  //   {
  //     name: 'Master Program',
  //     subcourses: [
  //       { name: 'Full Stack Master Program' },
  //       { name: 'Data Analytics' }
  //     ]
  //   },
  //   {
  //     name: 'Cloud Computing',
  //     subcourses: [
  //       { name: 'AWS Cloud' },
  //       { name: 'Azure Cloud' }
  //     ]
  //   },
  //   {
  //     name: 'Data Analytics',
  //     subcourses: [
  //       { name: 'Data Science' },
  //       { name: 'Machine Learning' }
  //     ]
  //   }
  // ];

  openLoginModal() {
    this.dialog.open(LoginComponent, {

      width: '600px', // Adjust width as needed
      height: 'auto',
      disableClose: true // Prevent closing the modal by clicking outside
    });
  }


  isMobileMenuOpen = false;
  isDropdownOpen = false;

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  toggleDropdown(event: Event) {
    this.isDropdownOpen = !this.isDropdownOpen;
    event.stopPropagation();
  }

  toggleSubMenu(event: Event) {
    const submenu = (event.target as HTMLElement).nextElementSibling as HTMLElement;
    submenu.classList.toggle('show');
    event.stopPropagation();
  }

}
