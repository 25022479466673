import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ApiService } from '../../shared/services/api-service.service';
import { LoginComponent } from '../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { BuyCourseComponent } from '../buy-course/buy-course.component';
@Component({
  selector: 'app-course-description',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, FooterComponent],
  templateUrl: './course-description.component.html',
  styleUrl: './course-description.component.css'
})
export class CourseDescriptionComponent {
  courseData: any;
  subcourseId: string | null = null;
  courseSyllabus: any[] = [];
  constructor(private route: ActivatedRoute, private api: ApiService, public dialog: MatDialog,) { }

  ngOnInit() {
    this.subcourseId = this.route.snapshot.paramMap.get('id');
    console.log("this.subcourseId", this.subcourseId);
    // Use the subcourseId to fetch course details or perform actions.
    this.getCousrseData();
  }

  getCousrseData() {
    this.api.get(`Course/GetCourseDetails/${this.subcourseId}`).subscribe((response: any) => {
      console.log(response);
      if (response.Status) {
        this.courseData = response.CourseList[0];
        this.courseSyllabus = this.courseData.Subjects
      }
    })
  }

  images: string[] = [
    'https://via.placeholder.com/200', // Replace with your image URLs
    'https://via.placeholder.com/200',
    'https://via.placeholder.com/200',
    'https://via.placeholder.com/200',
    'https://via.placeholder.com/200'
  ];

  courseTitle: string = 'Full Stack Master Program';

  totalAmount: number = 67000;
  discountOffered: number = 26800;
  amountToPay: number = this.totalAmount - this.discountOffered;

  downloadSyllabus() {
    alert('Syllabus Downloaded!');
  }

  batchSchedules = [
    { date: '28-10-2024', batch: 'Mon-Fri (Weekdays)', timings: '08:00 AM (IST)' },
    { date: '24-10-2024', batch: 'Mon-Fri (Weekdays)', timings: '08:00 AM (IST)' },
    { date: '26-10-2024', batch: 'Sat-Sun (Weekend)', timings: '11:00 AM (IST)' }
  ];

  getFees(batch: any) {
    console.log(`Getting fees for batch: ${batch.batch} on ${batch.date}`);
    // Implement any action when the Get Fees button is clicked
  }

  requestBatch() {
    console.log('Requesting a new batch...');
    // Implement the action for requesting a new batch
  }

  openLoginModal(ID : number, coursePrice: number, courseName : string) {
    this.dialog.open(BuyCourseComponent, {
      width: '400px', // Adjust width as needed
      disableClose: true, // Prevent closing the modal by clicking outside
      data : {ID, coursePrice, courseName}
    });
  }
}
